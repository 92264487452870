import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { useToast } from "@hooks/use-toast";
import { CoreAPITypes } from "@stellar/api-logic";
import { SdbProject } from "@custom-types/project-types";
import { deleteProjectSnapshot } from "@store/snapshots/snapshots-slice";
import DeleteIcon from "@assets/icons/new/delete_32px.svg?react";
import { fetchingSnapshotsFlagsSelector } from "@store/snapshots/snapshots-selector";
import { isResponseError } from "@context-providers/error-boundary/error-boundary-utils";
import { SnapshotActionButton } from "@pages/project-details/project-snapshots/snapshot-action-button";
import { SnapshotEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** The snapshot to be deleted */
  snapshot: CoreAPITypes.IProjectSnapshot;

  /** The selected project the snapshot belongs to */
  selectedProject: SdbProject;

  /** Whether the snapshot is opened in mobile version, and the button is collapsed in a menu. */
  isMobileVersion?: boolean;
}

/**
 * The title of the button to open the dialog, used either as a label in
 * mobile devices or tooltip on larger devices.
 */
const BUTTON_TITLE = "Delete SnapShot";

/**
 * Component that has an activator button to open the dialog to delete a project.
 * On mobile devices the button will be shown inside a menu, therefore shows the icon
 * and the label. On larger devices the button will be shown as an icon only, with
 * the label as tooltip.
 */
export function DeleteSnapshotDialog({
  snapshot,
  selectedProject,
  isMobileVersion,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { isDeletingSnapshot } = useAppSelector(fetchingSnapshotsFlagsSelector);
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Callback to delete the snapshot
   */
  async function deleteSnapshot(): Promise<void> {
    trackEvent({ name: SnapshotEvents.deleteSnapshot });

    try {
      const result = await dispatch(
        deleteProjectSnapshot({
          coreApiClient,
          projectId: selectedProject.id,
          snapshotId: snapshot.id,
        })
      );

      if (!isResponseError(result)) {
        showToast({
          type: "success",
          message: "User group created successfully",
        });
      }
    } finally {
      // Regardless of the result, close the dialog
      // The error is handled by the errors-slice
      setIsOpen(false);
    }
  }

  return (
    <>
      <SnapshotActionButton
        openDialog={() => setIsOpen(true)}
        icon={DeleteIcon}
        buttonTitle={BUTTON_TITLE}
        dataTestId="REMOVE_SNAPSHOT"
        isMobileVersion={isMobileVersion}
      />

      {/* Dialog with the details of the member to be removed */}
      <FaroDialog
        title="Delete SnapShot"
        confirmText="Delete"
        open={isOpen}
        confirmButtonColor="red"
        onConfirm={deleteSnapshot}
        onClose={() => setIsOpen(false)}
        isConfirmLoading={isDeletingSnapshot}
      >
        <Typography
          sx={{
            fontSize: "14px",
          }}
        >
          Are you sure you want to delete the snapshot{" "}
          <Box
            component="var"
            sx={{
              fontWeight: "bold",
            }}
          >
            {/* Component is of type var to prevent translation */}
            {snapshot.name}?
          </Box>
        </Typography>
      </FaroDialog>
    </>
  );
}
