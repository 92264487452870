import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { LabelWithHelp } from "@components/common/label-with-help";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import {
  AutoCompleteMenuOption,
  getMemberLabel,
} from "@components/common/members-autocomplete/members-autocomplete-utils";
import { isResponseError } from "@context-providers/error-boundary/error-boundary-utils";
import { useCompanyMembers } from "@hooks/use-company-members";
import { useToast } from "@hooks/use-toast";
import { Grid, Stack } from "@mui/material";
import { useAppParams } from "@router/router-helper";
import { useAppDispatch } from "@store/store-helper";
import { createTeamGroup } from "@store/team/team-thunk";
import { STACK_ERROR_SX } from "@styles/common-styles";
import { isValidEmail } from "@utils/member-utils";
import { TeamGroupEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useMemo, useState } from "react";

/** Renders the create button and dialog to create team */
export function CreateTeam(): JSX.Element {
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const coreApiClient = useCoreApiClient();
  const companyMembers = useCompanyMembers();
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();

  const [teamName, setTeamName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  /** Stores the ids of the users to be assigned as group managers */
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);

  /** Whether the create button should be disabled */
  const isCreateDisabled: boolean = useMemo(
    () => teamName.length < 1 || selectedMemberIds.length < 1,
    [selectedMemberIds.length, teamName.length]
  );

  /** Stores the members of the company in the format required by the autocomplete */
  const memberOptions: AutoCompleteMenuOption[] = useMemo(() => {
    return companyMembers.map((member) => ({
      label: getMemberLabel({ member }),
      value: member.identity,
      originalMember: member,
    }));
  }, [companyMembers]);

  /** Stores the ids of the members of the company */
  const memberIds = useMemo(() => {
    return companyMembers.map(
      (member) => member.id?.toLowerCase() ?? member.email.toLowerCase()
    );
  }, [companyMembers]);

  /** Stores the message to be displayed in the autocomplete */
  const membersMessage: AutoCompleteMessage | undefined = useMemo(() => {
    if (
      selectedMemberIds.some(
        (member) => !memberIds.includes(member.toLowerCase())
      )
    ) {
      return {
        type: "info",
        helperText:
          "Inviting members by email will also invite them to the workspace",
      };
    }
    return undefined;
  }, [selectedMemberIds, memberIds]);

  /** Open the dialog. */
  async function onOpenDialog(): Promise<void> {
    trackEvent({ name: TeamGroupEvents.startCreateNewTeam });
    setIsDialogOpen(true);
  }

  /** Resets the dialog content, and close the dialog. */
  async function onCloseDialog(): Promise<void> {
    setTeamName("");
    setDescription("");
    setIsDialogOpen(false);
  }

  /** Triggered when the create button is clicked, creates the group and closes the dialog */
  async function handleConfirm(): Promise<void> {
    if (!companyId) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await dispatch(
        createTeamGroup({
          coreApiClient,
          companyId: companyId,
          teamName,
          description,
          selectedMemberIds,
        })
      );

      if (!isResponseError(result)) {
        trackEvent({ name: TeamGroupEvents.createNewTeam });

        showToast({
          type: "success",
          message: "Snapshot deleted successfully",
        });
      }
    } finally {
      setIsLoading(false);
      onCloseDialog();
    }
  }

  return (
    <>
      {/* Button to open dialog */}
      <FaroButtonContained onClick={onOpenDialog}>
        New user group
      </FaroButtonContained>

      {/* Create project dialog */}
      <FaroDialog
        title="New user group"
        open={isDialogOpen}
        confirmText="Create"
        isConfirmDisabled={isCreateDisabled}
        onConfirm={handleConfirm}
        isConfirmLoading={isLoading}
        onClose={onCloseDialog}
      >
        <Grid maxWidth="100%" width="70vw">
          {/* Team name */}
          <Stack sx={STACK_ERROR_SX}>
            <LabelWithHelp title="User group name" isRequired />
            <FaroSimpleTextField
              autoFocus={true}
              placeholder="Enter user group name"
              size="small"
              fullWidth
              value={teamName}
              onChange={(event) => setTeamName(event.target.value)}
            />
          </Stack>

          {/* Team name */}
          <Stack sx={STACK_ERROR_SX}>
            <LabelWithHelp title="Description" />
            <FaroSimpleTextField
              autoFocus={false}
              placeholder="Enter description"
              size="small"
              fullWidth
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Stack>

          {/* Team members */}
          <Stack sx={STACK_ERROR_SX}>
            <MembersAutocomplete
              options={memberOptions}
              handleChange={setSelectedMemberIds}
              placeHolder="Enter name or email"
              message={membersMessage}
              validateNewOption={isValidEmail}
              // If one of the members is preselected, they should be added to the group by default.
              initialValue={memberOptions.filter(
                (memberOption) => memberOption.isPreselected
              )}
              labelTitle="Members"
            />
          </Stack>
        </Grid>
      </FaroDialog>
    </>
  );
}
